<script>
  let linkElement;

  window.onblur = function () {
    linkElement.href = "/favicon-sm.png";
  };
  window.onfocus = function () {
    linkElement.href = "/favicon.png";
  };
</script>

<svelte:head>
  <link
    bind:this={linkElement}
    rel="icon"
    type="image/png"
    href="/favicon.png"
  />
</svelte:head>
