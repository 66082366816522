<script>
  import Slider from "./_Slider.svelte";
</script>

<section id="nasze-wdrozenia">
  <div class="container">
    <h1 class="heading-with-underline heading-with-underline-primary">
      Nasze wdrożenia
    </h1>

    <div class="wrapper">
      <Slider />
    </div>
  </div>
</section>

<style>
  section {
    color: var(--color-primary);
    padding: var(--global-v-space) 0;
    text-align: center;
  }

  .wrapper {
    margin: var(--global-v-space) 0;
  }
</style>
