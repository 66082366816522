<script>
  import Spinner from "../../Components/Spinner.svelte";
  import { useForm, validators, required, email } from "svelte-use-form";

  const apiUrl = "https://app.librion.pl/notification";

  let honeypot = "";
  let promise = undefined;

  let formSubmitted = false;
  let messageSent = false;

  const resetPromiseAfterDelay = () =>
    setTimeout(() => {
      promise = undefined;
    }, 3000);

  const form = useForm();
  let descriptionInputRef;

  const handleClick = () => (promise = send());

  const send = async () => {
    formSubmitted = true;

    if (!$form.valid) {
      return;
    }

    const body = JSON.stringify({ data: $form.values });
    const method = "POST";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const res = await fetch(apiUrl, { method, headers, body });

    descriptionInputRef.value = "";

    if (res.status === 200) {
      messageSent = true;
      setTimeout(() => {
        messageSent = false;
      }, 3000);

      return;
    }

    throw new Error(res.errors);
  };
</script>

<form use:form>
  <h1>Prezentacja systemu</h1>
  <p>
    Wypełnij formularz, aby umówić się na prezentacje systemu i uzyskać więcej informacji.
  </p>
  <input name="name" placeholder="Imię i nazwisko" type="text" />
  <input name="beneficiaries" placeholder="Wydawnictwo" type="text" />
  <input
    name="email"
    class:error={!$form.email?.valid && ($form.email?.touched || formSubmitted)}
    use:validators={[required, email]}
    placeholder="Adres e-mail*"
    type="email"
  />

  <input name="phone" placeholder="Telefon" type="tel" />
  <textarea
    name="description"
    class:error={!$form.description?.valid &&
      ($form.description?.touched || formSubmitted)}
    use:validators={[required]}
    bind:this={descriptionInputRef}
    placeholder="Wiadomość*"
  />

  <!-- h-oneypot below -->
  <input
    style="position: fixed; left: -1000px; top: -1000px;"
    bind:value={honeypot}
    type="text"
    for="super important information about your private life"
    placeholder="super important information about your private life"
  />

  <div class="required-info">* pole obowiązkowe</div>

  <div class="input-wrapper">
    <input
      type="checkbox"
      id="agreement"
      name="agreement"
      class:error={!$form.agreement?.valid &&
        ($form.agreement?.touched || formSubmitted)}
      use:validators={[required]}
    />
    <label for="agreement"
      >Zgodnie z art.6 ust.1 lit. a ogólnego rozporządzenia o ochronie danych
      osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016)
      wyrażam zgodę na przetwarzanie danych osobowych w celu przygotowania
      oferty, odpowiedzi i&nbsp;późniejszego kontaktu.</label
    >
  </div>

  {#await promise}
    <button><Spinner /></button>
  {:then _res}
    {#if messageSent}
      <button class="btn-success"> Wiadomość wysłana </button>
    {:else}
      <button type="submit" on:click|preventDefault={handleClick}>
        Wyślij</button
      >
    {/if}
  {:catch _error}
    <button class="btn-error" use:resetPromiseAfterDelay
      >Wiadomość nie została wysłana</button
    >
  {/await}
</form>

<style>
  form {
    display: grid;
    grid-template-rows: 1fr min-content;
    margin: 0 0 0 3rem;
    width: 100%;
  }

  p {
    margin: 0 0 var(--global-v-space) 0;
  }

  input,
  textarea {
    display: block;
    width: inherit;
    resize: none;
    padding: 1rem;
    border: 1px solid #464444;
  }

  input {
    display: block;
    margin: 0 0 1rem 0;
    height: 3.125rem;
  }

  input[type="checkbox"] {
    height: unset;
    margin: 0;
  }

  textarea {
    height: 12rem;
  }

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: 55px;
    background: var(--color-secondary);
    text-transform: uppercase;
    font-size: 1.125rem;
    color: #fff;
    cursor: pointer;
    transition: all 1s ease;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .btn-error {
    background: var(--color-danger);
  }

  .btn-success {
    background: var(--color-success);
  }

  .required-info {
    font-size: 0.75rem;
    margin: 10px 0;
  }

  h1 {
    position: relative;
    top: -8px;
    margin: 0 0 var(--global-v-space) 0;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }

  .input-wrapper input {
    transform: scale(1.5);
  }

  .input-wrapper label {
    margin: 1rem 0px 1rem 1rem;
    padding: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 820px) {
    h1 {
      text-align: center;
    }

    form {
      margin: 0;
    }
  }

  .error,
  input.error + label {
    border: 3px solid var(--color-danger);
  }

  *:focus {
    outline: none;
  }
</style>
