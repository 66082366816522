<section id="rozliczenia">
  <div class="container margin-bottom">
    <h1 class="heading-with-underline heading-with-underline-primary">
      Rozliczenia
    </h1>
    <div class="grid">
      <div class="grid-item">
        <h2>Działanie systemu</h2>
        <p>
          Na podstawie konfiguracji umowy oraz danych sprzedażowych, system
          Librion tworzy rozliczenia honorariów po każdym zakończonym okresie
          rozliczeniowym. To znacząco przyspiesza pracę oraz minimalizuje ryzyko
          błędów.
        </p>
      </div>

      <div class="grid-item">
        <img src="static/zarys_procesu.png" alt="Zarys Procesu" />
      </div>

      <div class="grid-item">
        <h2>Zalety systemu</h2>
        <ul>
          <li>Sprawne generowanie rozliczeń honorariów</li>
          <li>Generowanie rachunków dla autorów</li>
          <li>Wydruk rozliczeń oraz rachunków</li>
          <li>Integracja z NBP - automatyczne pobranie kursów walut</li>
          <li>Obsługa języków PL i&nbsp;ENG dla rozliczeń</li>
          <li>Przeglądanie historii rozliczeń autora/tytułu</li>
        </ul>
      </div>

      <div class="grid-item">
        <h2>Szeroki zakres konfiguracji</h2>
        <ul>
          <li>
            Rozliczanie różnych typów osób uprawnionych (autor, agencja,
            spadkobierca, wydawca zagraniczny)
          </li>
          <li>
            Elastyczne okresy rozliczeniowe – roczne, półroczne, kwartalne,
            miesięczne
          </li>
          <li>Rozliczanie sprzedaży względem zaliczki</li>
          <li>Honoraria liczone od ceny okładkowej lub wartości sprzedaży</li>
          <li>Honoraria procentowe stałe lub progowe</li>
          <li>Możliwość czasowej zmiany sposobu rozliczeń z&nbsp;autorem</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<style>
  h1 {
    text-align: center;
    color: var(--color-primary);
    padding: var(--global-v-space) 0 var(--global-v-space) 0;
  }

  h2 {
    margin: 0 0 2rem;
  }

  li {
    line-height: var(--global-line-height);
  }

  img {
    width: 100%;
    height: auto;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    margin: 0 0.2rem 2rem 0.2rem;
  }

  .margin-bottom {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 820px) {
    .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  }
</style>
