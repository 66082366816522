<script>
  import Burger from "./_Burger.svelte";
  import { isMobileNavOpened } from "./_navbarState";
  import { fly, fade } from "svelte/transition";

  function toggle() {
    $isMobileNavOpened
      ? ($isMobileNavOpened = false)
      : ($isMobileNavOpened = true);
  }
</script>

<Burger />

{#if $isMobileNavOpened}
  <div transition:fade on:click={toggle} on:keypress={toggle} class="backdrop" />

  <div class="wrapper" transition:fly={{ x: -200, duration: 1000 }}>
    <a href="#top">
      <img
        src="static/logo_librion.png"
        alt="Logo aplikacji LibriOn"
        class="logo"
      />
    </a>

    <ul class="nav-list">
      <li>
        <a href="#o-systemie">O Systemie</a>
      </li>
      <li>
        <a href="#rozliczenia">Rozliczenia</a>
      </li>
      <li>
        <a href="#rozwoj-systemu">Rozwój Systemu</a>
      </li>
      <li>
        <a href="#nasze-wdrozenia">Nasze Wdrożenia</a>
      </li>
      <li>
        <a href="#wersja-demonstracyjna">Prezentacja systemu</a>
      </li>
      <li>
        <a href="#kontakt">Kontakt</a>
      </li>
    </ul>
  </div>
{/if}

<style>
  img {
    max-height: 30px;
    margin-bottom: 1rem;
  }

  ul {
    padding: 0;
  }

  ul li {
    list-style-type: none;
  }

  ul li a {
    color: var(--color-font);
    font-weight: 700;
    display: block;
    padding: 10px 0;
  }

  ul li a:hover {
    text-decoration: underline;
  }

  .wrapper {
    min-width: 40%;
    max-width: 80%;
    padding: 3rem;
    background: #fff;
    opacity: 0.9;
    position: fixed;
    height: 100vh;
  }

  .backdrop {
    background: #000;
    opacity: 0.6;
    position: absolute;
    height: 100vh;
    width: 100vw;
    cursor: pointer;
  }
</style>
