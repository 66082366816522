<footer class="scroll-snap" id="kontakt">
  <div class="container">
    <h1>Kontakt</h1>
    <address>
      <div class="addressline"><b>Email:</b> <a href="mailto:biuro@semanticore.pl">biuro@semanticore.pl</a></div>
      
      <div class="addressline"><b>Telefon:</b> <a href="tel:+48 798 891 646">+48 798 891 646</a></div>
    </address>
    <hr />

    <div class="copyright">
      Wszystkie prawa zastrzeżone. Semanticore, {new Date().getFullYear()}
    </div>
  </div>
</footer>

<style>
  footer {
    background: var(--color-tertiary);
    color: #fff;
  }

  h1 {
    padding: var(--global-v-space) 0 var(--global-v-space) 0;
  }

  a {
    color: inherit;
    font-style: normal;
    padding-left: 4px;
  }

  address {
    margin: 0 0 var(--global-v-space) 0;
  }

  a:hover {
    text-decoration: underline;
  }

  hr {
    height: 4px;
    background: #fff;
    margin: var(--global-v-space) 0;
  }
  .addressline {
    padding-top: 4px;
  }

  .copyright {
    text-align: right;
    padding: 0 0 var(--global-v-space) 0;
  }
</style>
