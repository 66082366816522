<script>
  import { onMount } from "svelte";

  let isBannerVisible = false;
  const COOKIE_CONSENT_KEY = "cookieConsent";

  onMount(() => {
    const consent = JSON.parse(localStorage.getItem(COOKIE_CONSENT_KEY));
    if (!consent) {
      isBannerVisible = true;
    } else if (consent.analytics) {
      enableGoogleAnalytics();
    }
  });

  function enableGoogleAnalytics() {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-D1NR922SW9";
    script.async = true;
    document.head.appendChild(script);

    
    
  }

  // Obsługa zgody użytkownika
  function acceptCookies() {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify({ analytics: true }));
    enableGoogleAnalytics();
    isBannerVisible = false;
  }

  function rejectCookies() {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify({ analytics: false }));
    isBannerVisible = false;
  }
</script>
<style>
    .cookie-banner {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #222;
      color: #fff;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 1000;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
    }
  
    .cookie-banner .content {
      margin-bottom: 1rem;
    }
  
    .cookie-banner button {
      margin: 1rem;
      padding: 0.5rem 2rem;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
  
    .cookie-banner button.accept {
      background: #4caf50;
      color: white;
    }
  
    .cookie-banner button.reject {
      background: #f44336;
      color: white;
    }
    .cookie-banner .actions {
        margin: 0 auto;
        text-align: center;
    }
  </style>
  
  {#if isBannerVisible}
    <div class="cookie-banner">
      <div class="content">
        <p>Używamy plików cookie, aby poprawić działanie strony i analizować ruch na niej za pomocą Google Analytics. Pliki cookie umożliwiają nam zbieranie anonimowych danych, takich jak liczba odwiedzających i najczęściej odwiedzane strony.</p>
        <p>Akceptując, zgadzasz się na przesyłanie anonimowych danych do Google w celach analitycznych. </p>
        <p style="color: #4caf50; text-decoration: underline;">Twój adres IP zostanie zanonimizowany i nie będzie używany do identyfikacji.</p>
      </div>
      <div class="actions">
        <button class="accept" on:click={acceptCookies}>Akceptuję</button>
        <button class="reject" on:click={rejectCookies}>Odrzucam</button>
      </div>
    </div>
  {/if}
