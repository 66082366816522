<script>
  import Form from "./_Form.svelte";

  const breakpoint = 820; // px

  let innerWidth, isMobile;

  $: setIsMobile(innerWidth);

  function setIsMobile(innerWidth) {
    isMobile = innerWidth < breakpoint;
  }
</script>

<svelte:window bind:innerWidth />

<section id="wersja-demonstracyjna" class="scroll-snap bg-primary">
  <div class="container wrapper">
    {#if !isMobile}
      <img src="static/demo_foto.jpg" alt="" width="317" height="763" />
    {/if}
    <Form />
  </div>
</section>

<style>
  section {
    background: var(--color-primary);
    color: #fff;
    padding: var(--global-v-space) 0;
  }

  img {
    display: block;
    max-height: 80%;
    min-width: 380px;
    height: auto;
  }

  .wrapper {
    display: flex;
  }
</style>
