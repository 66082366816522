<img src="static/bg_baner.jpg" alt="" width="1280" height="669" />

<style>
  img {
    display:block;
    max-height: 80%;
    width: 100%;
    height: auto;
  }
</style>
