<script>
  import Hero from "./Segments/Hero.svelte";
  import OSystemie from "./Segments/OSystemie.svelte";
  import Rozliczenia from "./Segments/Rozliczenia.svelte";
  import RozwojSystemu from "./Segments/RozwojSystemu.svelte";
  import NaszeWdrozenia from "./Segments/NaszeWdrozenia/NaszeWdrozenia.svelte";
  import Kontakt from "./Segments/Kontakt.svelte";
  import WersjaDemonstracyjna from "./Segments/WersjaDemonstracyjna/WersjaDemonstracyjna.svelte";

  import Navbar from "./Components/navbar/Navbar.svelte";
  import CookieBanner from "./Components/CookieBanner.svelte";
  import FaviconSwap from "./Features/FaviconSwap.svelte";

  import CssVariables from "./Config/CSSVariables.svelte";
</script>

<!-- Site -->
<Navbar />
<Hero />
<OSystemie />
<main>
  <CookieBanner />
  <Rozliczenia />
  <RozwojSystemu />
  <NaszeWdrozenia />
  <WersjaDemonstracyjna />
</main>
<Kontakt />

<!-- Features -->
<FaviconSwap />

<!-- Config -->
<CssVariables />
