<script>
  import { fade } from "svelte/transition";
  import NavbarDesktop from "./_NavbarDesktop.svelte";
  import NavbarMobile from "./_NavbarMobile.svelte";

  const breakpoint = 1150; // px

  let scrollY, lastScrollY, innerHeight, innerWidth, isMobile;

  let isScrolledDown = false;
  let isVisible = true;

  $: setIsScrolledDown(scrollY);
  $: setIsVisible(scrollY);
  $: setIsMobile(innerWidth);

  function setIsScrolledDown(scrollY) {
    const denominator = isMobile ? 4 : 2;
    isScrolledDown = scrollY > innerHeight / denominator;
  }

  function setIsMobile(innerWidth) {
    isMobile = innerWidth < breakpoint;
  }

  function setIsVisible(scrollY) {
    const denominator = 3;

    // Bugfix for scrolling too fast
    const diff = Math.abs(scrollY - lastScrollY);
    if (diff > 1 && diff < 100) {
      isVisible = scrollY < lastScrollY || scrollY < innerHeight / denominator;
    }

    lastScrollY = scrollY;
  }
</script>

<svelte:window bind:scrollY bind:innerHeight bind:innerWidth />

{#if isVisible}
  <nav in:fade out:fade opaque={isScrolledDown || isMobile} class="nav-bar">
    {#if isMobile}
      <NavbarMobile />
    {:else}
      <NavbarDesktop />
    {/if}
  </nav>
{/if}

<style>
  .nav-bar {
    position: fixed;
    width: 100%;
    height: 108px;
    z-index: 5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: rgba(255, 255, 255, 0.2);
    transition: background-color 0.7s linear;
    max-width: 100vw;
  }

  .nav-bar[opaque="true"] {
    background: #fff;
  }

  @media screen and (max-width: 1100px) {
    .nav-bar {
      height: 75px;
    }
  }
</style>
