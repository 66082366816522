export const data = [
  {
    alt: "Logo firmy Karakter",
    src: "static/wdrozenia/karakter.png",
  },
  {
    alt: "Logo wydawnictwa Dowody Na Istnienie",
    src: "static/wdrozenia/dowody-na-istnienie.png",
  },
  {
    alt: "Logo wydawnictwa Dwie Siostry",
    src: "static/wdrozenia/dwie-siostry.png",
  },
  {
    alt: "Logo wydawnictwa Krytyka Polityczna",
    src: "static/wdrozenia/krytyka-polityczna.png",
  },
];
