<header id="o-systemie" class="bg-primary">
  <div class="container">
    <h1>O systemie</h1>
    <p>
      System LibriOn ma za zadanie usprawnić, przyspieszyć i&nbsp;ustandaryzować
      elementy procesu wydawniczego. System stanowi rozszerzenie
      i&nbsp;uzupełnienie dla działających zazwyczaj w&nbsp;wydawnictwach
      systemów ERP w zakresie procesów finansowo-księgowych i&nbsp;magazynowych
      oraz systemów CRM i&nbsp;e-commerce w&nbsp;zakresie procesów
      sprzedażowych.
    </p>
  </div>
</header>

<style>
  header {
    color: #fff;
    text-align: center;
    padding: 0 0 var(--global-v-space) 0;

    position: relative;
    top: -4px; /* visual bug from image fix */
  }

  h1 {
    padding: var(--global-v-space) 0 var(--global-v-space) 0;
  }

  .bg-primary {
    background: var(--color-primary);
  }
</style>
