<script>
  import { Navigation, Pagination, A11y } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/svelte";

  import { data as slides } from "./_data";

  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";

  const breakPoints = {
    sm: 700,
    md: 1200,
  };

  let innerWidth;
  let slidesPerView;

  $: setSlidesPerView(innerWidth);

  function setSlidesPerView(innerWidth) {
    if (innerWidth < breakPoints.sm) {
      slidesPerView = 1;
    }

    if (innerWidth >= breakPoints.sm && innerWidth < breakPoints.md) {
      slidesPerView = 2;
    }

    if (innerWidth >= breakPoints.md) {
      slidesPerView = 2;
    }
  }
</script>

<svelte:window bind:innerWidth />

{#if slidesPerView}
  <Swiper
    modules={[Navigation, Pagination, A11y]}
    spaceBetween={50}
    {slidesPerView}
    navigation
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
  >
    {#each slides as { src, alt }}
      <SwiperSlide>
        <img {src} {alt} />
      </SwiperSlide>
    {/each}
  </Swiper>
{/if}

<style>
  img {
    width: 75%;
    height: 75%;
    margin: 15px;
  }

  :global(.swiper-pagination) {
    position: absolute !important;
  }

  :global(.swiper) {
    min-height: 200px !important;
    display: flex;
    align-items: center;
  }
</style>
