<div class="container centered-v spaced">
  <a href="#top">
    <img
      src="static/logo_librion.png"
      alt="Logo aplikacji LibriOn"
      class="logo"
    />
  </a>

  <ul class="nav-list">
    <li class="nav-item">
      <a href="#o-systemie">O Systemie</a>
    </li>
    <li class="nav-item">
      <a href="#rozliczenia">Rozliczenia</a>
    </li>
    <li class="nav-item">
      <a href="#rozwoj-systemu">Rozwój Systemu</a>
    </li>
    <li class="nav-item">
      <a href="#nasze-wdrozenia">Nasze Wdrożenia</a>
    </li>
    <li class="nav-item">
      <a href="#wersja-demonstracyjna">Prezentacja systemu</a>
    </li>
    <li class="nav-item">
      <a href="#kontakt">Kontakt</a>
    </li>
  </ul>

</div>

<style>
  .nav-bar[opaque="true"] .nav-item a {
    color: #000 !important;
  }

  .nav-list {
    display: flex;
    position: relative;
    top: 16px;
    margin: 0;
  }

  .nav-item {
    list-style-type: none;
  }

  .nav-item:not(:last-of-type) {
    margin: 0 18px 0 0;
  }

  .logo:hover {
    filter: brightness(90%);
  }

  .nav-item a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #fff;
    transition: color 0.4s ease;
  }

  .nav-item:hover > a {
    text-decoration: underline;
  }

  .centered-v {
    height: inherit;
    display: flex;
    align-items: center;
  }

  .spaced {
    display: flex;
    justify-content: space-between;
  }
</style>
