<section id="rozwoj-systemu">
  <div class="container">
    <h1>Rozwój Systemu</h1>
    <p>
      Kompleksowy system Librion umożliwi obsługę kolejnych trzech obszarów:
    </p>

    <div class="cards">
      <div class="card">
        <img
          src="static/bulb_icon.png"
          alt="Ikona żarówki, symbolizująca planowanie"
        />
        <h2>Planowanie</h2>
        <p>
          Przygotowanie propozycji wydawniczych wraz z&nbsp;analizą wariantową i&nbsp;kalkulacją opłacalności danego wydania.
        </p>
      </div>

      <div class="card">
        <img
          src="static/realizacja_icon.png"
          alt="Ikona żarówki, symbolizująca planowanie"
        />
        <h2>Realizacja</h2>
        <p>
          Obsługa wydania oraz działań promocyjnych wraz z&nbsp;zarządzaniem
          zadaniami oraz podwykonawstwem prac.
        </p>
      </div>

      <div class="card">
        <img
          src="static/licence_icon.png"
          alt="Ikona żarówki, symbolizująca planowanie"
        />
        <h2>Licencje</h2>
        <p>
          Zarządzanie umowami autorskimi wraz z&nbsp;informacją o zakresie licencji i&nbsp;notyfikacjami o&nbsp;wygasających prawach.
        </p>
      </div>
    </div>
  </div>
</section>

<style>
  section {
    background: url("../static/rozliczenie_bg.png");
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 0 0 var(--global-v-space);
  }

  h1 {
    padding: var(--global-v-space) 0 var(--global-v-space) 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .cards {
    display: flex;
    justify-items: space-between;
  }

  .card {
    width: inherit;
    margin: 3rem;
  }

  @media screen and (max-width: 1100px) {
    .cards {
      flex-direction: column;
    }
  }
</style>
